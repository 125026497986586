import axios from 'axios';
import handleError from 'services/logout';
import { API__PREFIX, API__VERSION } from 'utils/configs';

const goToTheTask = async (toolPath, taskId, thunkAPI) => {
  try {
    const { data } = await axios.get(
      `${API__PREFIX}${API__VERSION}${toolPath}/task/${taskId}/read`
    );

    return data;
  } catch (error) {
    handleError(error.response.status);

    return thunkAPI.rejectWithValue(error.message);
  }
};

export default goToTheTask;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API__PREFIX, BG_REMOVE__BASE_PATH, API__VERSION } from 'utils/configs';

import handleError from 'services/logout';
import getTaskList from 'services/getTaskList';
import goToTheTask from 'services/goToTheTask';
import deleteTask from 'services/deleteTask';

axios.defaults.withCredentials = true;

export const getBgTaskList = createAsyncThunk(
  'bgremove/gallery',

  async (_, thunkAPI) => {
    const data = getTaskList(BG_REMOVE__BASE_PATH, thunkAPI);

    return data;
  }
);

export const goToTheBgTask = createAsyncThunk(
  'bgremove/goToTheBgTask',

  async (taskId, thunkAPI) => {
    const data = goToTheTask(BG_REMOVE__BASE_PATH, taskId, thunkAPI);

    return data;
  }
);

export const removeBgTask = createAsyncThunk(
  'bgremove/removeTask',

  async (taskId, thunkAPI) => {
    const data = deleteTask(BG_REMOVE__BASE_PATH, taskId, thunkAPI);

    return data;
  }
);

//------------------

export const createBGTaskFromGallery = createAsyncThunk(
  'bgremove/createFromGallery',

  async (taskData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${API__PREFIX}${API__VERSION}${BG_REMOVE__BASE_PATH}/task/create-from-gallery`,
        taskData
      );
      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createBGTaskFromUploaded = createAsyncThunk(
  'bgremove/createFromUploaded',

  async (taskData, thunkAPI) => {
    try {
      const formData = new FormData();

      taskData.forEach((item, index) => {
        formData.append('input_files', taskData[index]);
      });
      const { data } = await axios.post(
        `${API__PREFIX}${API__VERSION}${BG_REMOVE__BASE_PATH}/task/create-from-uploaded`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

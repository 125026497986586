import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import handleError from 'services/logout';

import Logo from 'components/common/Logo';
import icon from 'media/images/svgSprite.svg';
import BetaLable from 'components/common/BetaLable';
import InDevLable from 'components/common/InDevLable';

import {
  BackDrop,
  MenuWrap,
  CloseButton,
  IconClose,
  MenuLogoWrap,
  ToolList,
  Tool,
  ElemLink,
  NavIconWrap,
  ToolsIcon,
  TitleWrap,
  ToolsTitle,
  BottomSection,
  LogoutButtonWrap,
  LogoutButton,
} from 'components/SharedLayout/MainMenu/MainMenu.styled';

const mainMenuRoot = document.getElementById('burger-menu');

const toolsMeta = [
  {
    title: 'AI PHOTO SHOOTING',
    link: 'ai-photoshooting/gallery',
    toolIcon: 'ai-photoshooting',
  },
  {
    title: 'AI GENERATE MODELS',
    link: 'ai-generate-models/gallery',
    toolIcon: 'ai-generate-models',
  },
  {
    title: 'IMAGE UPSCALING',
    link: 'image-upscaling/gallery',
    toolIcon: 'upscale',
  },
  {
    title: 'BACKGROUND REMOVE',
    link: 'bg-remove/gallery',
    toolIcon: 'removebg',
  },
  {
    title: 'AI FASHION STORE',
    link: 'ai-fashion-store/gallery',
    toolIcon: 'fashion-store',
  },
];

const MainMenu = ({ onCloseMenu, isClosing, isVisible }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const handlePressESC = e => {
      if (e.code === 'Escape') {
        onCloseMenu();
      }
    };

    window.addEventListener('keydown', handlePressESC);

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('keydown', handlePressESC);
    };
  }, [onCloseMenu]);

  function handleClickOnBackDrop(e) {
    if (e.target.nodeName === 'DIV' && e.currentTarget.nodeName === 'DIV') {
      onCloseMenu();
    }
  }

  function onLogout() {
    handleError(401);
    navigate('signin');
    onCloseMenu();
  }

  function handleClickLink(e) {
    e.stopPropagation();
    onCloseMenu();
  }

  return createPortal(
    <BackDrop
      onClick={handleClickOnBackDrop}
      $opacity={isVisible}
      $isOpenClose={isClosing}
    >
      <MenuWrap $isOpenClose={isClosing} $opacity={isVisible}>
        <CloseButton type="button" onClick={handleClickLink}>
          <IconClose>
            <use href={`${icon}#icon-arrow-left-2`}></use>
          </IconClose>
        </CloseButton>

        <MenuLogoWrap onClick={handleClickLink}>
          <Logo iconName={'logo'} />
          <BetaLable
            topLablePos={'4px'}
            rightLablePos={'-42px'}
            topMessagePos={'58px'}
            bottomMessagePos={'auto'}
            leftMessagePos={'-40px'}
            translateXValue={'0'}
            lableLocation={'menu'}
          />
        </MenuLogoWrap>

        <ToolList>
          {toolsMeta.map(tool => {
            return (
              <Tool key={tool.toolIcon} onClick={handleClickLink}>
                <ElemLink to={tool.link}>
                  <NavIconWrap>
                    <ToolsIcon>
                      <use href={`${icon}#icon-${tool.toolIcon}`}></use>
                    </ToolsIcon>
                  </NavIconWrap>
                  <TitleWrap>
                    <ToolsTitle>{tool.title}</ToolsTitle>
                    {tool.toolIcon === 'ai-generate-models' && <InDevLable />}
                  </TitleWrap>
                </ElemLink>
              </Tool>
            );
          })}
        </ToolList>
        <BottomSection>
          <ElemLink to="pricing" onClick={handleClickLink}>
            <NavIconWrap>
              <ToolsIcon>
                <use href={`${icon}#icon-pricing`}></use>
              </ToolsIcon>
            </NavIconWrap>
            <ToolsTitle>Pricing</ToolsTitle>
          </ElemLink>
          <LogoutButtonWrap type="button" onClick={onLogout}>
            <LogoutButton>
              <ToolsIcon>
                <use href={`${icon}#icon-logout`}></use>
              </ToolsIcon>
            </LogoutButton>
            <ToolsTitle>Logout</ToolsTitle>
          </LogoutButtonWrap>
        </BottomSection>
      </MenuWrap>
    </BackDrop>,
    mainMenuRoot
  );
};

export default MainMenu;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BASE_URL, API__PREFIX, API__VERSION } from 'utils/configs';

import handleError from 'services/logout';

axios.defaults.baseURL = `${BASE_URL}`;
axios.defaults.withCredentials = true;

export const getUserData = createAsyncThunk(
  'userdata/getUserData',

  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${API__PREFIX}${API__VERSION}/user/profile/read`
      );

      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

import axios from 'axios';
import handleError from 'services/logout';
import { API__PREFIX, API__VERSION } from 'utils/configs';

const getTaskList = async (toolPath, thunkAPI) => {
  let taskType = '';
  if (toolPath !== `/ai-fashion-store/order`) {
    taskType = '/task';
  }

  try {
    const { data } = await axios.get(
      `${API__PREFIX}${API__VERSION}${toolPath}${taskType}/query`
    );

    return data;
  } catch (error) {
    handleError(error.response.status);

    return thunkAPI.rejectWithValue(error.message);
  }
};

export default getTaskList;

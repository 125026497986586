import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  selectFirstName,
  selectUserEmail,
} from '../../../redux/user-data/selectors';

import { IconButtonByContent } from 'components/common/Buttons/Buttons';
import icon from 'media/images/svgSprite.svg';

import {
  UserMenuHeadWrap,
  UserIcon,
  UserNameWrap,
  UserMeta,
  UserNameInput,
  EditIcon,
  UserMail,
  UserCabinetLink,
} from 'components/user/UserHeader/UserHeader.styled';

const UserHeader = ({ orientation, pageType, onOpenUserCabinet }) => {
  const [firstName, setFirstName] = useState(null);
  const [userEmail, setUserEmail] = useState('example@gmail.com');

  const [isUserNameChanging, setIsUserNameChanging] = useState(false);

  const userName = useSelector(selectFirstName);
  const email = useSelector(selectUserEmail);

  useEffect(() => {
    if (userName !== null) {
      setFirstName(userName);
    }
    if (email !== '') {
      setUserEmail(email);
    }
  }, [email, userName]);

  function onChangeName(e) {
    e.stopPropagation();
    setIsUserNameChanging(true);
  }

  function onSaveName(e) {
    e.stopPropagation();
    setIsUserNameChanging(false);
  }

  return (
    <UserMenuHeadWrap $orientation={orientation} $pageType={pageType}>
      <UserIcon>
        <use href={`${icon}#icon-user`}></use>
      </UserIcon>
      <UserMeta>
        <UserNameWrap>
          {isUserNameChanging ? (
            <>
              <UserNameInput
                type="text"
                onChange={e => setFirstName(e.target.value)}
                onClick={e => e.stopPropagation()}
                value={firstName || ''}
                maxLength={17}
              />
              <IconButtonByContent
                handleClick={onSaveName}
                isDisabled={!firstName || firstName.trim() === ''}
              >
                <EditIcon $type="check">
                  <use href={`${icon}#icon-check`}></use>
                </EditIcon>
              </IconButtonByContent>
            </>
          ) : (
            <h5>{firstName || 'Name'}</h5>
          )}

          {!isUserNameChanging && (
            <IconButtonByContent handleClick={onChangeName}>
              <EditIcon $type="pencil">
                <use href={`${icon}#icon-pencil`}></use>
              </EditIcon>
            </IconButtonByContent>
          )}
        </UserNameWrap>
        <UserMail>{userEmail}</UserMail>
        {pageType !== 'user-cabinet' && (
          <UserCabinetLink type="button" onClick={onOpenUserCabinet}>
            to account
          </UserCabinetLink>
        )}
      </UserMeta>
    </UserMenuHeadWrap>
  );
};

export default UserHeader;

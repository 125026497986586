import { useState, useEffect } from 'react';
import _ from 'lodash';
import icon from 'media/images/svgSprite.svg';
import welcomeVideoMp4 from 'media/video/mody-welcome-4.mp4';
import welcomeVideoWebm from 'media/video/mody-welcome-4.webm';
import splashScreen from 'media/images/splash_screen.png';

import {
  WelcomeSection,
  ModalIconClose,
  VideoWrap,
  PlayIcon,
} from 'components/WelcomeVideo/WelcomeVideo.styled';

const WelcomeVideo = ({ onCloseWelcomeModal }) => {
  const [isVideoRuning, setIsVideoRuning] = useState(false);

  useEffect(() => {
    const welcomeVideo = document.getElementById('welcome-video');

    if (welcomeVideo) {
      welcomeVideo.addEventListener('loadeddata', () =>
        setLastSavedTime(welcomeVideo)
      );
      welcomeVideo.addEventListener('play', () => setIsVideoRuning(true));
      welcomeVideo.addEventListener('pause', () => setIsVideoRuning(false));
      welcomeVideo.addEventListener(
        'timeupdate',
        _.throttle(e => onTimeChange(e), 1000)
      );
    }
  }, []);

  function onTimeChange(e) {
    if (!e.target) return;

    const seconds = e.target.currentTime;
    try {
      localStorage.setItem(
        'welcome-video-current-time',
        JSON.stringify(seconds)
      );
    } catch (error) {
      console.error('Seting error: ', error.message);
    }
  }

  function setLastSavedTime(videoElem) {
    const savedTime = JSON.parse(
      localStorage.getItem('welcome-video-current-time')
    );
    const fullTime = videoElem.duration;

    if (savedTime && savedTime !== fullTime) {
      videoElem.currentTime = savedTime;
    }
  }

  return (
    <WelcomeSection>
      <ModalIconClose onClick={onCloseWelcomeModal}>
        <use href={`${icon}#icon-close`}></use>
      </ModalIconClose>
      <h3>Welcome video</h3>
      <VideoWrap>
        {!isVideoRuning && (
          <PlayIcon onClick={() => setIsVideoRuning(true)}>
            <use href={`${icon}#icon-play`}></use>
          </PlayIcon>
        )}

        <video
          id="welcome-video"
          controls
          aria-label="Welcome video"
          preload="metadata"
          poster={splashScreen}
          controlsList="nodownload "
          description="The video shows a brief instruction on how to use the service."
        >
          <source src={welcomeVideoMp4} type="video/mp4" />
          <source src={welcomeVideoWebm} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </VideoWrap>
    </WelcomeSection>
  );
};

export default WelcomeVideo;

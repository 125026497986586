import { Amplify } from 'aws-amplify';
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Heading,
} from '@aws-amplify/ui-react';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import { USER_POOL_ID, USER_POOL_CLIENT_ID } from 'utils/configs';

import App from 'components/App';
import logo from 'media/images/logo_orgn.svg';

import { vars } from 'utils/variables';
import '@aws-amplify/ui-react/styles.css';
import 'index.css';

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Mod-y logo" src={logo} width={288} height={98} />
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.medium} 0 0 ${tokens.space.xl}`}
          color={`${vars.darkBlack100}`}
          fontSize={16}
          level={3}
        >
          Sign in with your username and password
        </Heading>
      );
    },
  },

  SignUp: {
    FormFields() {
      return (
        <>
          <Authenticator.SignUp.FormFields />

          <p className="agree-text">
            * By signing up, you agree to our{' '}
            <a
              href="https://www.mod-y.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer nofollow"
              title="Company Terms"
              className="agree-link"
            >
              Terms
            </a>{' '}
            of Use and acknowledge you’ve read our{' '}
            <a
              href="https://www.mod-y.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer nofollow"
              title="Company Privacy Policy"
              className="agree-link"
            >
              Privacy Policy
            </a>
          </p>
        </>
      );
    },
  },
};

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: USER_POOL_ID,
      userPoolClientId: USER_POOL_CLIENT_ID,
      // loginWith: {
      //   email: true,
      // },
      // signUpVerificationMethod: 'code',
      userAttributes: {
        email: {
          required: true,
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Authenticator components={components}>
            <App />
          </Authenticator>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

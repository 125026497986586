import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PrivateRoute from 'components/PrivateRoute';

import SignInUp from 'pages/auth/SignInUp/SignInUp';

import SharedLayout from 'components/SharedLayout/SharedLayout';

const CreatePage = lazy(() => import('pages/ai-photoshooting/CreatePage'));
const GalleryPage = lazy(() => import('pages/ai-photoshooting/GalleryPage'));
const TaskDetailsPage = lazy(() =>
  import('pages/ai-photoshooting/TaskDetailsPage')
);

const ModelsCreatePage = lazy(() =>
  import('pages/ai-generate-models/ModelsCreatePage')
);
const ModelsGalleryPage = lazy(() =>
  import('pages/ai-generate-models/ModelsGalleryPage')
);
const ModelsTaskDetailsPage = lazy(() =>
  import('pages/ai-generate-models/ModelsTaskDetailsPage')
);

const BgRemoveCreatePage = lazy(() =>
  import('pages/bg-remove/BgRemoveCreatePage')
);
const BgRemoveGalleryPage = lazy(() =>
  import('pages/bg-remove/BgRemoveGalleryPage')
);
const BgRemoveTaskDetailsPage = lazy(() =>
  import('pages/bg-remove/BgRemoveTaskDetailsPage')
);

const ImageUpscalingCreatePage = lazy(() =>
  import('pages/image-upscaling/ImageUpscalingCreatePage')
);
const ImageUpscalingGalleryPage = lazy(() =>
  import('pages/image-upscaling/ImageUpscalingGalleryPage')
);
const ImageUpscalingTaskDetailsPage = lazy(() =>
  import('pages/image-upscaling/ImageUpscalingTaskDetailsPage')
);

const PricePage = lazy(() => import('pages/PricePage'));
const Contacts = lazy(() => import('pages/Contacts'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));

const FashionStoreCreatePage = lazy(() =>
  import('pages/fashion-store/FashionStoreCreatePage')
);
const FashionStoreGalleryPage = lazy(() =>
  import('pages/fashion-store/FashionStoreGalleryPage')
);
const FashionStoreOrderDetailsPage = lazy(() =>
  import('pages/fashion-store/FashionStoreOrderDetailsPage')
);

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route
            index
            element={
              <PrivateRoute>
                <FashionStoreGalleryPage />
              </PrivateRoute>
            }
          />

          <Route path="signin" element={<SignInUp />} />

          <Route
            path="ai-photoshooting"
            element={
              <PrivateRoute>
                <CreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-photoshooting/gallery"
            element={
              <PrivateRoute>
                <GalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-photoshooting/details/:taskId"
            element={
              <PrivateRoute>
                <TaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="ai-generate-models"
            element={
              <PrivateRoute>
                <ModelsCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-generate-models/gallery"
            element={
              <PrivateRoute>
                <ModelsGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-generate-models/details/:taskId"
            element={
              <PrivateRoute>
                <ModelsTaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="bg-remove"
            element={
              <PrivateRoute>
                <BgRemoveCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="bg-remove/gallery"
            element={
              <PrivateRoute>
                <BgRemoveGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="bg-remove/details/:taskId"
            element={
              <PrivateRoute>
                <BgRemoveTaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="image-upscaling"
            element={
              <PrivateRoute>
                <ImageUpscalingCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="image-upscaling/gallery"
            element={
              <PrivateRoute>
                <ImageUpscalingGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="image-upscaling/details/:taskId"
            element={
              <PrivateRoute>
                <ImageUpscalingTaskDetailsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="pricing"
            element={
              <PrivateRoute>
                <PricePage />
              </PrivateRoute>
            }
          />
          <Route
            path="help-and-contacts"
            element={
              <PrivateRoute>
                <Contacts />
              </PrivateRoute>
            }
          />

          <Route
            path="ai-fashion-store"
            element={
              <PrivateRoute>
                <FashionStoreCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-fashion-store/gallery"
            element={
              <PrivateRoute>
                <FashionStoreGalleryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="ai-fashion-store/order/:orderId"
            element={
              <PrivateRoute>
                <FashionStoreOrderDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <NotFoundPage />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import styled from 'styled-components';
import { vars } from 'utils/variables';

const deleteBtnSize = {
  big: '64px',
  medium: '32px',
  small: '16px',
};

const deleteBtnColor = {
  big: vars.secondaryBlue,
  medium: vars.secondaryBlue,
  small: vars.dark70,
};

const iconBtnFill = { black: vars.darkBlack100 };
const iconBtnHoverFill = { black: vars.primaryHover };
const iconBtnBGColor = { none: 'transparent' };

const buttonBgColor = {
  primary_color: { common: vars.primaryPrimary, hover: vars.primaryHover },
  secondary_color: {
    common: vars.secondaryBlue,
    hover: vars.secondaryBlueHover,
  },
};

const buttonColor = {
  light: vars.light100,
};

export const BtnByContent = styled.button`
  padding: 15px 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 24px;

  background-color: ${props => buttonBgColor[props.$gbColor].common};
  color: ${props => buttonColor[props.$textColor]};

  font-size: 17px;
  font-weight: 600;
  line-height: 20px;

  transition: background-color ${vars.transitionTimingFunction};

  &:hover,
  &:active,
  &:focus-visible {
    background-color: ${props => buttonBgColor[props.$gbColor].hover};
  }
`;

export const IconBtnByContent = styled.button`
  padding: 0;
  background-color: transparent;
  line-height: 0;

  &:disabled {
    opacity: 0.3;
  }
`;

export const ExtraButton = styled.button`
  padding: 5px 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.primaryPrimary};
  background-color: transparent;

  border-radius: ${vars.mainBorderRadius};
  border: 1px solid ${vars.primaryPrimary};
  outline: none;

  font-size: 15px;
  font-weight: 500;
  line-height: 20px;

  transition: color ${vars.transitionTimingFunction},
    background-color ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    color: ${vars.light100};
    background-color: ${vars.primaryHover};
  }
`;

export const ExitButton = styled.button`
  padding: 5px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.darkBlack100};
  background-color: transparent;

  opacity: 0.5;

  transition: color ${vars.transitionTimingFunction},
    opacity ${vars.transitionTimingFunction};

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus-visible {
    color: ${vars.primaryHover};
    opacity: 0.8;
  }
`;

export const IconBtn = styled.button`
  width: 100%;
  height: 100%;

  padding: 0;

  background-color: ${props => iconBtnBGColor[props.$bgColor]};

  & > svg {
    fill: ${props => iconBtnFill[props.$fill]};

    transition: fill ${vars.transitionTimingFunction};
  }

  &:hover > svg {
    fill: ${props => iconBtnHoverFill[props.$fill]};
  }
`;

//-------------------Slider------------------------

export const SliderButton = styled.button`
  width: 42px;
  height: 100%;

  padding: 0;

  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;
  outline: none;
  z-index: 10;

  &:hover > svg,
  &:focus-visible > svg {
    fill: ${vars.darkBlack100};
    background-color: ${vars.secondaryLightBlue};
  }
`;

export const PrevBtn = styled(SliderButton)`
  left: 22px;
`;

export const NextBtn = styled(SliderButton)`
  right: 22px;
`;

export const SliderIcon = styled.svg`
  width: 40px;
  height: 40px;

  fill: ${vars.darkTransparent70};
  border-radius: 8px;
  background-color: transparent;

  transition: fill ${vars.transitionTimingFunction},
    background-color ${vars.transitionTimingFunction};
`;

//-------------------Image List------------------------

export const AddImgButton = styled.button`
  width: 100%;
  min-height: 66px;

  padding: 15px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  color: ${vars.darkTransparent70};
  background-color: ${vars.secondaryLightBlue};
  border-radius: ${vars.mainBorderRadius};
  border: transparent;
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  & h3 {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
  }

  & p {
    font-size: 11px;
    font-weight: 400;
  }

  &:hover,
  &:focus-visible {
    background-color: ${vars.secondaryMediumBlue};
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;

    &:hover,
    &:focus-visible {
      background-color: ${vars.secondaryLightBlue};
    }
  }
`;

//--------------Delete -------------------

export const DeleteBtn = styled.button`
  width: 100%;
  height: 100%;

  padding: 0;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  border-radius: 8px;
  border: none;
  outline: none;
  overflow: hidden;
  opacity: 0;

  transition: opacity ${vars.transitionTimingFunction},
    background-color ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    opacity: 1;
    background-color: ${vars.lightTransparent30};
  }
`;

export const DeleteIcon = styled.svg`
  width: ${props => (props.$size ? deleteBtnSize[props.$size] : '32px')};
  height: ${props => (props.$size ? deleteBtnSize[props.$size] : '32px')};
  fill: ${props =>
    props.$size ? deleteBtnColor[props.$size] : vars.secondaryBlue};

  transition: fill ${vars.transitionTimingFunction};
`;

export const DeleteSmallBtn = styled.button`
  width: 16px;
  height: 16px;

  padding: 0;

  position: absolute;
  top: -8px;
  right: -8px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  border-radius: 50%;
  border: none;
  outline: none;
  overflow: hidden;
  opacity: 0;

  transition: opacity ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    opacity: 1;
  }

  &:hover > svg,
  &:focus-visible > svg {
    fill: ${vars.secondaryBlue};
  }
`;

//-------------------Create Submit------------------------

export const SubmitButton = styled.button`
  height: 66px;
  width: 100%;

  padding: 15px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${vars.light100};
  background-color: ${vars.primaryPrimary};
  border-radius: ${vars.mainBorderRadius};
  border: transparent;
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  font-size: 17px;
  font-weight: 600;
  line-height: 20px;

  &:hover,
  &:focus-visible {
    background-color: ${vars.primaryHover};
  }

  &:disabled {
    background-color: ${vars.primaryHover30};
    cursor: default;
  }
`;

//----------------------Gallery-------------------------

export const CreateBtn = styled.button`
  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.darkTransparent70};
  background-color: ${vars.secondaryLightBlue};

  border-radius: ${props => (props.$mob ? '100px' : vars.mainBorderRadius)};
  border: transparent;
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  font-size: 17px;
  font-weight: 600;
  line-height: 20px;

  &:hover,
  &:focus-visible {
    background-color: ${vars.secondaryMediumBlue};
  }

  @media screen and (min-width: 768px) {
    padding: 15px 20px;
  }
`;

export const GettingInfoBtn = styled.button`
  width: 100%;
  height: 32px;

  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.light100};
  background-color: ${vars.primaryPrimary};

  border-radius: ${vars.mainBorderRadius};
  border: transparent;
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;

  &:hover,
  &:focus-visible {
    background-color: ${vars.primaryHover};
  }

  @media screen and (min-width: 992px) {
    width: 77px;
  }

  @media screen and (min-width: 1200px) {
    width: 80px;
  }
`;

export const ExtraBtnIcon = styled.button`
  width: 100%;
  height: 32px;

  padding: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${vars.lightTransparent70};

  border-radius: ${vars.mainBorderRadius};
  border: 1px solid ${vars.primaryPrimary};
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    background-color: ${vars.primaryPrimary};
  }

  &:hover > svg,
  &:focus-visible > svg {
    fill: ${vars.light100};
  }

  &:disabled {
    background-color: ${vars.lightTransparent70};
    opacity: 0.3;
  }

  &:disabled > svg {
    fill: ${vars.darkBlack100};
  }

  @media screen and (min-width: 992px) {
    width: 77px;
  }

  @media screen and (min-width: 1200px) {
    width: 80px;
  }
`;

//------------------------Details------------------------

export const DetailsToolBtn = styled.button`
  padding: 8px 43px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  color: ${vars.darkTransparent70};
  background-color: ${props => {
    switch (props.$actionType) {
      case 'assess':
        return vars.secondaryViolet;
      case 'download':
        return vars.secondaryLightBlue;
      case 'paste':
        return vars.primaryRose;
      case 'generate':
        return vars.secondaryViolet;
      default:
        return vars.darkTransparent5;
    }
  }};
  border-radius: ${vars.mainBorderRadius};
  border: 1px solid;
  border-color: ${props => {
    switch (props.$actionType) {
      case 'download':
        return vars.secondaryMediumBlue;
      default:
        return 'transparent';
    }
  }};
  outline: none;

  transition: background-color ${vars.transitionTimingFunction},
    border-color ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    background-color: ${props => {
      switch (props.$actionType) {
        case 'assess':
          return vars.secondaryVioletDark;
        case 'download':
          return vars.secondaryMediumBlue;
        case 'paste':
          return vars.primaryPrimary;
        case 'generate':
          return vars.secondaryVioletDark;
        default:
          return vars.darkTransparent5;
      }
    }};

    border-color: ${props => {
      switch (props.$actionType) {
        case 'assess':
          return 'transparent';
        case 'download':
          return vars.secondaryLightBlue;
        case 'paste':
          return 'transparent';
        default:
          return vars.secondaryMediumBlue;
      }
    }};
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${props => {
      switch (props.$actionType) {
        case 'assess':
          return vars.secondaryViolet;
        case 'download':
          return vars.secondaryLightBlue;
        case 'paste':
          return vars.primaryRose;
        default:
          return vars.darkTransparent5;
      }
    }};
    border-color: ${props => {
      switch (props.$actionType) {
        case 'assess':
          return 'transparent';
        case 'download':
          return vars.secondaryLightBlue;
        case 'paste':
          return 'transparent';
        default:
          return vars.secondaryMediumBlue;
      }
    }};
    opacity: 0.3;
    cursor: default;
  }

  @media screen and (min-width: 768px) {
    padding: 6px 12px;
  }
`;

//----------------------Modal Button---------------------

export const ModalBtnLight = styled.button`
  width: 100%;
  height: 100%;

  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.primaryPrimary};
  background-color: transparent;

  border-radius: ${vars.mainBorderRadius};
  border: 1px solid ${vars.primaryPrimary};
  outline: none;

  transition: color ${vars.transitionTimingFunction},
    background-color ${vars.transitionTimingFunction};

  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;

  &:hover,
  &:focus-visible {
    color: ${vars.light100};
    background-color: ${vars.primaryHover};
  }

  @media screen and (min-width: 768px) {
    height: 48px;
  }
`;

export const ModalBtnAccent = styled.button`
  width: 100%;
  height: 48px;

  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.light100};
  background-color: ${vars.primaryPrimary};

  border-radius: ${vars.mainBorderRadius};
  border: none;
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;

  &:hover,
  &:focus-visible {
    background-color: ${vars.primaryHover};
  }
`;

export const ModalBtnGratitude = styled.button`
  width: 58px;
  height: 58px;

  padding: 0;

  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 50%;
  outline: none;

  &:hover,
  &:focus-visible {
    background-color: ${vars.primaryHover};
  }
`;

export const GratitudeIcon = styled.svg`
  width: 100%;
  height: 100%;
`;

//---------------------Price Page---------------------------

export const PriceBtn = styled.button`
  width: 100%;
  height: 37px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${vars.light100};
  background-color: ${vars.primaryPrimary};
  border-radius: ${vars.mainBorderRadius};
  border: none;
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  font-size: 15px;
  font-weight: 500;
  line-height: 20px;

  &:hover,
  &:focus-visible {
    background-color: ${vars.primaryHover};
  }
`;

export const BuyBtn = styled.button`
  padding: 5px 20px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props => (props.$free ? vars.primaryPrimary : vars.light100)};
  background-color: ${props =>
    props.$free ? vars.light100 : vars.primaryPrimary};

  border-radius: ${vars.mainBorderRadius};
  border: 1px solid;
  border-color: ${props => (props.$free ? vars.primaryPrimary : 'transparent')};
  outline: none;

  transition: background-color ${vars.transitionTimingFunction},
    border-color ${vars.transitionTimingFunction};

  &:disabled {
    opacity: 0.3;
  }

  &:hover,
  &:focus-visible {
    color: ${props => (props.$free ? vars.light100 : vars.light100)};
    background-color: ${props =>
      props.$free ? vars.primaryPrimary : vars.primaryHover};
  }
`;

//--------------------Fashion Store--------------------------

export const MainAccentBtn = styled.button`
  height: 100%;
  width: 100%;

  padding: 15px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${vars.light100};
  background-color: ${vars.primaryPrimary};
  border-radius: ${vars.mainBorderRadius};
  border: transparent;
  outline: none;

  transition: background-color ${vars.transitionTimingFunction};

  font-size: 17px;
  font-weight: 600;
  line-height: 20px;

  &:hover,
  &:focus-visible {
    background-color: ${vars.primaryHover};
  }

  &:disabled {
    background-color: ${vars.primaryHover30};
    cursor: default;
  }
`;

export const ApprovalBtn = styled.button`
  width: 40px;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${vars.primaryPrimary};
  background-color: ${vars.light100};
  border: 1px solid ${vars.primaryPrimary};
  border-radius: ${vars.mainBorderRadius};

  font-size: 11px;
  font-weight: 500;
  line-height: 20px;

  transition: background-color ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    color: ${vars.light100};
    background-color: ${vars.primaryHover};
  }
`;

export const EditBtn = styled.button`
  min-width: 80px;
  height: 28px;
  padding: 2px 12px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props =>
    props.$actionType === 'save' ? vars.primaryHover : vars.dark70};
  background-color: ${vars.light100};
  border: 1px solid;
  border-color: ${props =>
    props.$actionType === 'save' ? vars.primaryHover : vars.dark70};
  border-radius: ${vars.mainBorderRadius};

  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  transition: background-color ${vars.transitionTimingFunction},
    color ${vars.transitionTimingFunction};

  &:hover,
  &:focus-visible {
    color: ${vars.light100};
    background-color: ${props =>
      props.$actionType === 'save' ? vars.primaryHover : vars.dark70};
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  &:disabled:hover {
    color: ${props =>
      props.$actionType === 'save' ? vars.primaryHover : vars.dark70};
    background-color: ${vars.light100};
  }

  & > div {
    width: 130px;
    padding: 4px;
    position: absolute;
    bottom: 28px;
    right: 10px;

    opacity: 0;
    border-top-left-radius: ${vars.mainBorderRadius};
    border-top-right-radius: ${vars.mainBorderRadius};
    border-bottom-left-radius: ${vars.mainBorderRadius};

    font-size: 11px;
    font-weight: 400;
    line-height: normal;

    transition: opacity ${vars.transitionTimingFunction};
  }

  &:disabled:hover > div {
    opacity: 1;
    color: ${vars.light100};
    background-color: ${vars.darkBlack100};
  }
`;

import { createSlice } from '@reduxjs/toolkit';
import {
  createTask,
  createAiPhotoshootingTaskFromGallery,
  getAvatars,
  getAiPhotoshootingTaskList,
  goToTheAiPhotoshootingTask,
  removeTask,
} from './operations';
import { SIZES } from 'utils/constants';
import { TESTTASK_ID, TEST_IMAGE_ID } from 'utils/configs';

import defaultInputImage from 'media/images/test_input.jpg';
import defaultResultImage from 'media/images/test_result.jpg';

const defaultTask = {
  id: TESTTASK_ID,
  avatar: { image: '07966_00.jpeg' },
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      result_image: defaultResultImage,
    },
  ],
  status: 'test',
};

let aiPhotoshootingInitialState = {
  models: [],
  taskList: [],
  lastTask: null,
  taskDetails: null,
  taskStatus: null,
  filters: {
    size: `${SIZES.medium}`,
    gender: 'woman',
    cloth_type: 'upper_body',
    age: 'adult',
    view: 'front',
  },
  isLoading: false,
  responseErrorMessage: null,
};

const handlePending = state => {
  state.isLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.responseErrorMessage = payload;
  state.isLoading = false;
};

export const aiPhotoshootingSlice = createSlice({
  name: 'aiphotoshooting',
  initialState: aiPhotoshootingInitialState,

  reducers: {
    setFilterValue(state, { payload }) {
      state.filters = { ...state.filters, ...payload };
    },

    setDefaultTaskDetails(state) {
      state.lastTask = TESTTASK_ID;
      state.taskDetails = defaultTask;
      state.taskStatus = 'test';
    },

    resetTaskDetails(state) {
      state.lastTask = null;
      state.taskDetails = null;
      state.taskStatus = null;
    },

    resetTaskList(state) {
      state.taskList = [];
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAvatars.pending, handlePending)
      .addCase(getAvatars.rejected, handleRejected)
      .addCase(getAvatars.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.models = payload;
        state.responseErrorMessage = null;
      })
      .addCase(createTask.pending, handlePending)
      .addCase(createTask.rejected, handleRejected)
      .addCase(createTask.fulfilled, (state, { payload }) => {
        state.lastTask = payload.id;
        state.isLoading = false;
        state.responseErrorMessage = null;
      })
      .addCase(createAiPhotoshootingTaskFromGallery.pending, handlePending)
      .addCase(createAiPhotoshootingTaskFromGallery.rejected, handleRejected)
      .addCase(
        createAiPhotoshootingTaskFromGallery.fulfilled,
        (state, { payload }) => {
          state.lastTask = payload.id;
          state.isLoading = false;
          state.responseErrorMessage = null;
        }
      )
      .addCase(getAiPhotoshootingTaskList.pending, handlePending)
      .addCase(getAiPhotoshootingTaskList.rejected, handleRejected)
      .addCase(getAiPhotoshootingTaskList.fulfilled, (state, { payload }) => {
        if (payload.length > 0) {
          state.taskList = payload;
        } else {
          state.taskList = [defaultTask];
        }
        state.isLoading = false;
        state.responseErrorMessage = null;
      })
      .addCase(goToTheAiPhotoshootingTask.pending, handlePending)
      .addCase(goToTheAiPhotoshootingTask.rejected, handleRejected)
      .addCase(goToTheAiPhotoshootingTask.fulfilled, (state, { payload }) => {
        state.taskDetails = payload;
        state.taskStatus = payload.status;
        state.isLoading = false;
        state.responseErrorMessage = null;
      })
      .addCase(removeTask.pending, handlePending)
      .addCase(removeTask.rejected, handleRejected)
      .addCase(removeTask.fulfilled, (state, { payload }) => {
        state.taskList = state.taskList.filter(task => task.id !== payload);
        state.isLoading = false;
        state.responseErrorMessage = null;
      });
  },
});

export const {
  setFilterValue,
  setDefaultTaskDetails,
  resetTaskDetails,
  resetTaskList,
} = aiPhotoshootingSlice.actions;
export const aiPhotoshootingReducer = aiPhotoshootingSlice.reducer;

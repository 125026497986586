import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  API__PREFIX,
  AI_FASHION_STORE__BASE_PATH,
  API__VERSION,
} from 'utils/configs';

import handleError from 'services/logout';
import getTaskList from 'services/getTaskList';
import deleteTask from 'services/deleteTask';

export const createFSOrderWithTwoFileTypes = createAsyncThunk(
  'fashionStore/createWithTwoFileTypes',

  async (orderData, thunkAPI) => {
    try {
      let taskId = null;
      const formData = new FormData();

      if (orderData.ownImages.length > 0) {
        orderData.ownImages.forEach((item, index) => {
          formData.append('images', orderData.ownImages[index]);
        });

        const { data } = await axios.post(
          `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/create-from-uploaded`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        taskId = data.id;
      }

      if (
        orderData.galleryImages.length > 0 &&
        orderData.ownImages.length > 0
      ) {
        await axios.post(
          `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${taskId}/image/add-from-gallery`,
          { images: orderData.galleryImages }
        );
      }

      if (
        orderData.galleryImages.length > 0 &&
        orderData.ownImages.length === 0
      ) {
        const { data } = await axios.post(
          `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/create-from-gallery`,
          { images: orderData.galleryImages }
        );

        taskId = data.id;
      }

      return taskId;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getFSOrderList = createAsyncThunk(
  'fashionStore/getFSOrderList',

  async (_, thunkAPI) => {
    const data = getTaskList(AI_FASHION_STORE__BASE_PATH, thunkAPI);

    return data;
  }
);

export const deleteFSOrder = createAsyncThunk(
  'fashionStore/deleteFSOrder',

  async (orderId, thunkAPI) => {
    const data = deleteTask(AI_FASHION_STORE__BASE_PATH, orderId, thunkAPI);

    return data;
  }
);

export const getOrderDetails = createAsyncThunk(
  'fashionStore/getOrderDetails',

  async (orderId, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${orderId}/read`
      );

      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addImageFromGallery = createAsyncThunk(
  'fashionStore/addImageFromGallery',

  async (orderData, thunkAPI) => {
    try {
      await axios.post(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/image/add-from-gallery`,
        { images: orderData.images }
      );

      const { data } = await axios.get(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/read`
      );

      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addImageFromUploaded = createAsyncThunk(
  'fashionStore/addImageFromUploaded',

  async (orderData, thunkAPI) => {
    try {
      const formData = new FormData();

      orderData.images.forEach(item => {
        formData.append('images', item);
      });

      await axios.post(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/image/add-from-uploaded`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const { data } = await axios.get(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/read`
      );

      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteImageFS = createAsyncThunk(
  'fashionStore/deleteImageFS',

  async (imageData, thunkAPI) => {
    try {
      await axios.post(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${imageData.orderId}/image/${imageData.imageId}/delete`
      );

      return imageData.imageId;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateFSOrderDetails = createAsyncThunk(
  'fashionStore/updateFSOrderDetails',

  async (orderData, thunkAPI) => {
    try {
      await axios.post(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${orderData.orderId}/details/update`,
        orderData.newMeta
      );

      return orderData;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const generateFSOrderDetails = createAsyncThunk(
  'fashionStore/generateFSOrderDetails',

  async (reqParams, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `${API__PREFIX}${API__VERSION}${AI_FASHION_STORE__BASE_PATH}/${reqParams.orderId}/details/generate`,
        reqParams.meta
      );

      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

import { signOut } from 'aws-amplify/auth';

const handleError = async status => {
  const intercomObj = document.getElementById('intercom-frame');

  if (status === 401 || status === 403) {
    if (intercomObj) {
      window.Intercom('shutdown');
    }

    signOut();
  }
};

export default handleError;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  BASE_URL,
  AI_GENERATE_MODELS__BASE_PATH,
  API__PREFIX,
  API__VERSION,
} from 'utils/configs';

import handleError from 'services/logout';
import getTaskList from 'services/getTaskList';
import goToTheTask from 'services/goToTheTask';
import deleteTask from 'services/deleteTask';

axios.defaults.baseURL = `${BASE_URL}`;
axios.defaults.withCredentials = true;

export const getAiGenerateModelsAvatars = createAsyncThunk(
  'aigeneratemodels/getAvatars',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `${API__PREFIX}${API__VERSION}${AI_GENERATE_MODELS__BASE_PATH}/avatar/query
        `
      );
      return data;
    } catch (error) {
      handleError(error.response.status);

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createAiGenerateModelsTask = createAsyncThunk(
  'aigeneratemodels/create',

  async (_, thunkAPI) => {
    /**Will be changed with the ready backend */

    return 'id';
  }
);

export const getAiGenerateModelsTaskList = createAsyncThunk(
  'aigeneratemodels/gallery',

  async (_, thunkAPI) => {
    const data = getTaskList(AI_GENERATE_MODELS__BASE_PATH, thunkAPI);

    /**Will be changed with the ready backend */
    //   return data;
    console.log(data);
    return [];
  }
);

export const goToTheAiGenerateModelsTask = createAsyncThunk(
  'aigeneratemodels/goToTheTask',

  async (taskId, thunkAPI) => {
    const data = goToTheTask(AI_GENERATE_MODELS__BASE_PATH, taskId, thunkAPI);

    return data;
  }
);

export const removeAiGenerateModelsTask = createAsyncThunk(
  'aigeneratemodels/removeTask',

  async (taskId, thunkAPI) => {
    const data = deleteTask(AI_GENERATE_MODELS__BASE_PATH, taskId, thunkAPI);

    return data;
  }
);

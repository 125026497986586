import { createSlice } from '@reduxjs/toolkit';
import {
  createImgUpscalingTaskFromGallery,
  createImgUpscalingTaskFromUploaded,
  getImgUpscalingTaskList,
  goToTheImgUpscalingTask,
  removeImgUpscalingTask,
} from './operations';

import { TESTTASK_ID, TEST_IMAGE_ID } from 'utils/configs';

import defaultInputImage from 'media/images/test_input.jpg';

const defaultTask = {
  id: TESTTASK_ID,
  images: [
    {
      id: TEST_IMAGE_ID,
      input_image: defaultInputImage,
      result_image: defaultInputImage,
    },
  ],
  status: 'test',
};

let imageUpscalingInitialState = {
  upscalingTaskList: [],
  upscalingLastTask: null,
  upscalingTaskDetails: null,
  upscalingTaskStatus: null,
  upscalingIsLoading: false,
  upscalingResponseErrorMessage: null,
};

const handlePending = state => {
  state.upscalingIsLoading = true;
};

const handleRejected = (state, { payload }) => {
  state.upscalingResponseErrorMessage = payload;
  state.upscalingIsLoading = false;
};

export const imgUpscalingSlice = createSlice({
  name: 'imageupscaling',
  initialState: imageUpscalingInitialState,

  reducers: {
    setDefaultTaskDetails(state) {
      state.upscalingLastTask = TESTTASK_ID;
      state.upscalingTaskDetails = defaultTask;
      state.upscalingTaskStatus = 'test';
    },

    upscalingResetTaskDetails(state) {
      state.upscalingLastTask = null;
      state.upscalingTaskDetails = null;
      state.upscalingTaskStatus = null;
    },

    upscalingResetTaskList(state) {
      state.upscalingTaskList = [];
    },
  },

  extraReducers: builder => {
    builder
      .addCase(createImgUpscalingTaskFromGallery.pending, handlePending)
      .addCase(createImgUpscalingTaskFromGallery.rejected, handleRejected)
      .addCase(
        createImgUpscalingTaskFromGallery.fulfilled,
        (state, { payload }) => {
          state.upscalingLastTask = payload.id;
          state.upscalingIsLoading = false;
          state.upscalingResponseErrorMessage = null;
        }
      )
      .addCase(createImgUpscalingTaskFromUploaded.pending, handlePending)
      .addCase(createImgUpscalingTaskFromUploaded.rejected, handleRejected)
      .addCase(
        createImgUpscalingTaskFromUploaded.fulfilled,
        (state, { payload }) => {
          state.upscalingLastTask = payload.task_id;
          state.upscalingIsLoading = false;
          state.upscalingResponseErrorMessage = null;
        }
      )
      .addCase(getImgUpscalingTaskList.pending, handlePending)
      .addCase(getImgUpscalingTaskList.rejected, handleRejected)
      .addCase(getImgUpscalingTaskList.fulfilled, (state, { payload }) => {
        if (payload.length > 0) {
          state.upscalingTaskList = payload;
        } else {
          state.upscalingTaskList = [defaultTask];
        }

        state.upscalingIsLoading = false;
        state.upscalingResponseErrorMessage = null;
      })
      .addCase(goToTheImgUpscalingTask.pending, handlePending)
      .addCase(goToTheImgUpscalingTask.rejected, handleRejected)
      .addCase(goToTheImgUpscalingTask.fulfilled, (state, { payload }) => {
        state.upscalingTaskDetails = payload;
        state.upscalingTaskStatus = payload.status;
        state.upscalingIsLoading = false;
        state.upscalingResponseErrorMessage = null;
      })
      .addCase(removeImgUpscalingTask.pending, handlePending)
      .addCase(removeImgUpscalingTask.rejected, handleRejected)
      .addCase(removeImgUpscalingTask.fulfilled, (state, { payload }) => {
        state.upscalingTaskList = state.upscalingTaskList.filter(
          task => task.id !== payload
        );
        state.upscalingIsLoading = false;
        state.upscalingResponseErrorMessage = null;
      });
  },
});

export const {
  setDefaultTaskDetails,
  upscalingResetTaskDetails,
  upscalingResetTaskList,
} = imgUpscalingSlice.actions;
export const imgUpscalingReducer = imgUpscalingSlice.reducer;

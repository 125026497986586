import { useState } from 'react';

import useWindowWidth from 'hooks/useWindowWidth';

import Logo from 'components/common/Logo';
import icon from 'media/images/svgSprite.svg';
import WelcomeVideo from 'components/WelcomeVideo';
import Modal from 'components/common/modals/Modal';
import BetaLable from 'components/common/BetaLable';

import {
  FooterContainer,
  FooterWrap,
  FooterLogoWrap,
  CopyrightRights,
  CopyrightLink,
  FooterMenu,
  FooterSection,
  FooterLink,
  TutorialLink,
  FooterPolicyAndTermsLink,
  FooterSocLink,
  FooterSocIcon,
} from 'components/SharedLayout/Footer/Footer.styled';

const Footer = () => {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  const width = useWindowWidth();
  const isDesktop = width > 991;
  const isTablet = width < 992;

  return (
    <>
      <FooterWrap>
        <FooterContainer>
          <section>
            <FooterLogoWrap>
              <Logo iconName={'logo'} />
              <BetaLable
                topLablePos={'1px'}
                rightLablePos={'-30px'}
                topMessagePos={'auto'}
                bottomMessagePos={'56px'}
                leftMessagePos={'50%'}
                translateXValue={'-50%'}
                lableLocation={'footer'}
              />
            </FooterLogoWrap>
            {isDesktop && (
              <CopyrightRights>
                <span>&#169;</span> Copyright{' '}
                <CopyrightLink
                  href="https://www.mod-y.com/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Company website"
                >
                  Mod-y.com
                </CopyrightLink>
                . All Rights Reserved
              </CopyrightRights>
            )}
          </section>

          <FooterMenu>
            <FooterSection>
              <h5>Tools</h5>
              <FooterLink to="ai-photoshooting/gallery">
                AI Photo Shooting
              </FooterLink>
              <FooterLink to="image-upscaling/gallery">
                Image Upscaling
              </FooterLink>
              <FooterLink to="bg-remove/gallery">Background Remove</FooterLink>
              <FooterLink to="ai-fashion-store/gallery">
                AI Fashion Store
              </FooterLink>
            </FooterSection>

            <FooterSection>
              <h5>Legal</h5>
              <FooterPolicyAndTermsLink
                href="https://www.mod-y.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="Company Privacy Policy"
              >
                Privacy Policy
              </FooterPolicyAndTermsLink>
              <FooterPolicyAndTermsLink
                href="https://www.mod-y.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="Company Service Terms"
              >
                Service Terms
              </FooterPolicyAndTermsLink>
            </FooterSection>

            <FooterSection>
              <h5>Support</h5>
              <FooterLink to="pricing">Pricing</FooterLink>
              <TutorialLink
                type="button"
                onClick={() => setIsWelcomeModalOpen(true)}
              >
                Tutorials
              </TutorialLink>
              <FooterLink to="help-and-contacts">Contacts</FooterLink>
            </FooterSection>

            <FooterSection>
              <h5>Social Links</h5>
              <section>
                <FooterSocLink
                  href="https://www.linkedin.com/company/mod-y/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Company page on LinkedIn"
                >
                  <FooterSocIcon>
                    <use href={`${icon}#icon-linkedin`}></use>
                  </FooterSocIcon>
                </FooterSocLink>

                <FooterSocLink
                  href="https://www.facebook.com/modyaifashion"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Company page on Facebook"
                >
                  <FooterSocIcon>
                    <use href={`${icon}#icon-facebook`}></use>
                  </FooterSocIcon>
                </FooterSocLink>

                <FooterSocLink
                  href="https://www.instagram.com/mod_y.ai?igsh=MW9mbWV5cDZwc2hobQ%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="Company page on Instagram"
                >
                  <FooterSocIcon>
                    <use href={`${icon}#icon-instagram`}></use>
                  </FooterSocIcon>
                </FooterSocLink>
              </section>
            </FooterSection>
          </FooterMenu>

          {isTablet && (
            <CopyrightRights>
              <span>&#169;</span> Copyright{' '}
              <CopyrightLink
                href="https://www.mod-y.com/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="Company website"
              >
                Mod-y.com
              </CopyrightLink>
              . All Rights Reserved
            </CopyrightRights>
          )}
        </FooterContainer>
      </FooterWrap>
      {isWelcomeModalOpen && (
        <Modal closeModal={() => setIsWelcomeModalOpen(false)}>
          <WelcomeVideo
            onCloseWelcomeModal={() => setIsWelcomeModalOpen(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default Footer;
